export const langPCMap: Record<string, string> = {
  'zh-CN': '简体中文',
  'zh-HK': '繁體中文',
  'en-US': 'English',
  'vi-VN': 'Tiếng Việt',
  'ja-JP': '日本語',
  'ko-KR': '한국어',
  'th-TH': 'ไทย',
  'id-ID': 'Bahasa Indonesia',
  'ms-MY': 'Bahasa Melayu',
  'fil-PH': 'Filipino',
  'my-MM': 'မြန်မာ',
  'km-KH': 'ភាសាខ្មែរ',
  'lo-LA': 'ພາສາລາວ',
}

export const langH5Map: Record<string, string> = {
  'zh-CN': '中文',
  'zh-HK': '中文(繁)',
  'en-US': 'ENG',
  'vi-VN': 'Việt',
}
